Salitre.apartment_enquiry = {
  init: function() {
    this.attachHandlers();
  },
  attachHandlers: function() {
    function toggleFormControls(formControl) {
      formControl.addEventListener('focus', function(e) {
        var formField = this.parentElement.parentElement;

        formField.classList.add('is-active');
      });

      formControl.addEventListener('blur', function(e) {
        var formField = this.parentElement.parentElement;

        if (this.value) {
          formField.classList.add('is-filled');
        } else {
          formField.classList.remove('is-filled');
        }

        formField.classList.remove('is-active');
      });
    }

    Salitre.utils.toggleElement('.wpcf7-form-control', toggleFormControls);
  }
};
