/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// this will be our namespace
// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Salitre = {};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var fire;
    var namespace = Salitre;
    funcname = (funcname === undefined) ? 'init' : funcname;
    fire = func !== '';
    fire = fire && namespace[func];
    fire = fire && typeof namespace[func][funcname] === 'function';

    if (fire) {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    var bodyClasses = document.body.className.replace(/-/g, '_').split(/\s+/);

    // Fire common init JS
    UTIL.fire('common');

    // Fire page-specific init JS, and then finalize JS
    for (var i = 0, l = bodyClasses.length; i < l; i++) {
      UTIL.fire(bodyClasses[i]);
      UTIL.fire(bodyClasses[i], 'finalize');
    }

    // Fire common finalize JS
    UTIL.fire('common', 'finalize');
  }
};

function ready(fn) {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

ready(UTIL.loadEvents);