Salitre.common = {
  init: function() {
    this.startMenu();
    this.adjustMenu();
  },
  startMenu: function() {
    var burger = document.querySelector('.js-burger');
    var body = document.querySelector('body');

    function toggleMenu() {
      if (body.classList.contains('show-menu') === true) {
        body.classList.remove('show-menu');
      } else {
        body.classList.add('show-menu');
      }
    }

    burger.addEventListener('click', function(e) {
      e.preventDefault();

      if (this.classList.contains('is-active') === true) {
        this.classList.remove('is-active');
      } else {
        this.classList.add('is-active');
      }

      toggleMenu();
    });
  },
  adjustMenu: function() {
    // add correct top value to the menu and make it visible
    function fixPosition() {
      var menu = document.querySelector('.js-menu');
      var menuH = menu.offsetHeight;

      menu.style.top = -menuH + 'px';
      menu.style.visibility = 'visible';
    }

    window.onresize = fixPosition;

    // little hack
    setTimeout(function() {
      fixPosition();
    }, 0);
  },
  finalize: function() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  }
};