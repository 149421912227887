(function(){
  var cookies;

  function readCookie(name, c, C, i) {
    if (cookies) {
      return cookies[name];
    }

    c = document.cookie.split('; ');
    cookies = {};

    for (i = c.length - 1; i >= 0; i--) {
       C = c[i].split('=');
       cookies[C[0]] = C[1];
    }

    console.log(name);
    return cookies[name];
  }

  window.readCookie = readCookie; // or expose it however you want
})();

Salitre.home = {
  init: function() {
    this.attachHandlers();
    this.startAnimation();

    /* if (readCookie('lang')) {
      // remove language selection overlay
      document.querySelector('.js-language-select').style.display = 'none';
      this.startTimerToChangePage();
    }*/
  },
  startAnimation: function() {
    var svg = document.querySelector('.js-logo > div');

    setTimeout(function() {
      svg.classList.add('animate');
    }, 1000);
  },
  attachHandlers: function() {
    var logo = document.querySelector('.js-logo');
    var introText = document.querySelector('.js-intro-text');

    function animationOnEnd(event) {
      logo.className += ' hidden';
    }

    function transitionOnEnd(event) {
      logo.style.display = 'none';
      introText.className += ' active';
    }

    logo.addEventListener(Salitre.utils.animEndEventName, animationOnEnd);
    logo.addEventListener(Salitre.utils.transEndEventName, transitionOnEnd);

  },
  startTimerToChangePage: function() {
    var homeBtn = document.querySelector('.js-home-btn');

    setTimeout(function() { homeBtn.click(); }, 15000);
  }
};
