function findAncestor (el, cls) {
  while (!el.classList.contains(cls)) {
    el = el.parentElement;
  }

  return el;
}

var onEndEvents = (function() {
  var el = document.createElement('salitre');

  return function(eventNames) {
    for (var name in eventNames) {
      if (el.style[name] !== undefined) {
        return eventNames[name];
      }
    }

    return false;
  };
})();

Salitre.utils = {
  toggleElement: function(selector, cb) {
    var elements = document.querySelectorAll(selector);

    for (var i = elements.length - 1; i >= 0; i--) {
      var toggle = elements[i];

      cb(toggle);
    }
  },
  collapse: function(el) {
    function toggleHandler(toggle) {
      toggle.addEventListener('click', function(e) {
        e.preventDefault();
        var collapse = findAncestor(this, 'collapse');

        if (collapse.classList.contains('is-open')) {
          collapse.classList.remove('is-open');
        } else {
          collapse.classList.add('is-open');
        }
      });
    }

    this.toggleElement(el, toggleHandler);
  },
  revealImages: function() {
    var catalogItems = document.querySelectorAll('.js-catalog-item');
    var self = this;

    for (var i = 0, l = catalogItems.length; i < l; i++) {
      self.addHoverToImages(catalogItems[i]);
    }
  },
  addHoverToImages: function(item) {
    var itemW = item.offsetWidth;
    var itemOffsetLeft = item.offsetLeft;
    var firstChild = item.children[0];
    var secondChild = item.children[1];
    var canAnimate = firstChild.classList.contains('js-animation') &&
      secondChild.classList.contains('js-animation');

    if (!canAnimate) {
      return false;
    }

    var isAnimating = false;
    var hastoChange = false;

    function animate(xPosition) {
      if (xPosition < itemW / 2) {
        firstChild.classList.add('animate');
      } else {
        secondChild.classList.add('animate');
      }
    }

    item.addEventListener('mouseenter', function(e) {
      var mouseX = e.pageX - itemOffsetLeft;

      animate(mouseX);
      isAnimating = true;
    });

    item.addEventListener('mouseout', function(e) {
      firstChild.classList.remove('animate');
      secondChild.classList.remove('animate');
    });

    item.addEventListener(Salitre.utils.transEndEventName, function(event) {
      isAnimating = false;

      if (hastoChange) {
        animate(mouseX);
        hastoChange = false;
      }
    });

    item.addEventListener('mousemove', function(e) {
      if (!isAnimating) {
        var firstChildActive = firstChild.classList.contains('animate');
        var secondChildActive = secondChild.classList.contains('animate');
        var threshold = 100;

        mouseX = e.pageX - itemOffsetLeft;

        if ((mouseX > itemW / 2 + threshold) && firstChildActive) {
          item.children[0].classList.remove('animate');
          hastoChange = true;
        }

        if ((mouseX < itemW / 2 - threshold) && secondChildActive) {
          item.children[1].classList.remove('animate');
          hastoChange = true;
        }
      }
    });
  }
};

// Animation callback
var animEndEventNames = {
 'WebkitAnimation' : 'webkitAnimationEnd',
 'MozAnimation'   : 'animationend',
 'animation'       : 'animationend'
};
Salitre.utils.animEndEventName = onEndEvents(animEndEventNames);

// Transition callback
var transEndEventNames = {
  WebkitTransition : 'webkitTransitionEnd',
  MozTransition    : 'transitionend',
  OTransition      : 'oTransitionEnd otransitionend',
  transition       : 'transitionend'
};
Salitre.utils.transEndEventName = onEndEvents(transEndEventNames);
